@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Raleway:wght@300&family=Source+Serif+Pro&display=swap");
@font-face {
  font-family: "aleobold";
  src: url("../fonts/aleo-bold-webfont.woff2") format("woff2"), url("../fonts/aleo-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "harabararegular";
  src: url("../fonts/harabara-webfont.woff2") format("woff2"), url("../fonts/harabara-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "myriadPro";
  src: url("../fonts/myriadPro-Regular.woff2") format("woff2"), url("../fonts/myriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ebrima-Bold";
  src: url("../fonts/ebrima-Bold.woff2") format("woff2"), url("../fonts/ebrima-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gotham-Medium";
  src: url("../fonts/gotham-Medium.woff2") format("woff2"), url("../fonts/gotham-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Custom code goes here. A template should always ship with an empty custom.css */
/*  général  */
.img-100 {
  min-width: 100% !important;
}

.order-message-form,
.order-options {
  display: none !important;
}

/* fin général  */
/*   page produit   */
.col-product-info h2 {
  font-size: 1rem !important;
}

/*   fin page produit   */
/* .cbp-hrmenu .cbp-hrsub-inner, .cbp-hrmenu .cbp-hrsub-inner a, .cbp-hrmenu .cbp-hrsub-inner a:link { color: #643f3e!important; } .cbp-hrmenu li#cbp-hrmenu-tab-7 .menu-element-id-11>.cbp-menu-column-inner .cbp-column-title { color: #7aadc7!important; font-weight: bold !important; } .cbp-hrmenu li#cbp-hrmenu-tab-7 .menu-element-id-3>.cbp-menu-column-inner .cbp-column-title { color: #7aadc7!important; font-weight: bold !important; } .cbp-hrmenu li#cbp-hrmenu-tab-7 .menu-element-id-12{ margin: 0 auto !important; width: 80% !important; } */
.cbp-hrmenu li#cbp-hrmenu-tab-7 .menu-element-id-12 > .cbp-menu-column-inner .cbp-column-title {
  display: inline !important;
  color: #7aadc7 !important;
  font-weight: bold !important;
  /*text-align: center !important; padding-top: 7px !important; padding-bottom: 7px !important; */
  font-size: 14px !important;
  font-style: italic !important;
  background-color: #a7c8d9;
  padding: 2px 6px 2px 4px;
}

.menu-element-id-20 .cbp-column-title {
  display: inline !important;
  color: #7aadc7 !important;
  font-weight: bold !important;
  /*text-align: center !important; padding-top: 7px !important; padding-bottom: 7px !important; */
  font-size: 14px !important;
  font-style: italic !important;
  background-color: #a7c8d9;
  padding: 2px 6px 2px 4px;
}

/*****Mobile menu cacher celui du thème***/
.mobile-header-style-2 {
  display: none !important;
}

.footer-container #footer .rm-header .rm-language,
.rm-header .rm-language {
  display: none !important;
}

/**/
/** Menu mobile **/
.rm-header,
#footer .rm-header {
  height: 60px !important;
  border: 0 none !important;
}

.rm-header,
.rm-header .rm-logo-container {
  height: 60px !important;
}

.footer-container #footer .rm-header .rm-cart,
.rm-header .rm-cart {
  font-size: 30px !important;
  line-height: 55px;
  height: 60px;
}

.rm-header .rm-icon-container a {
  color: #7aadc7 !important;
}

.rm-header .rm-cart .count,
#footer .rm-header .rm-cart .count {
  background: #777 !important;
  width: 12px !important;
  text-align: center;
  line-height: 15px;
  border-radius: 30px;
  min-width: 16px;
  height: 16px;
  font-size: 0.8rem;
  position: absolute;
  right: -4px;
  padding: 1px 5px;
}

.rm-login-bar {
  background-color: #7aadc7 !important;
}

.rm-header .rm-trigger::before,
#footer .rm-header .rm-trigger::before {
  background: #7aadc7 !important;
  box-shadow: 0 6px rgba(255, 255, 255, 0), 0 10px #7aadc7, 0 18px rgba(255, 255, 255, 0), 0 20px #7aadc7;
}

.rm-trigger::before {
  top: 8px;
  height: 3px;
  left: -5px;
  width: 27px;
}

.rm-login-bar a {
  color: white !important;
}

.rm-search-bar__input {
  background: #a7c8d9 !important;
}

.rm-level__item--back {
  color: white !important;
}

#footer li a.rm-level__item,
.rm-level__item {
  border: 0 none !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  color: #0e004c;
}

.rm-level__title {
  border: 0 none !important;
}

.rm-level__item--link-1,
.rm-level__item--link-2,
.rm-level__item--link-3,
.rm-level__item--link-4 {
  padding-left: 10px !important;
}

.rm-level__container .icon-arrow:hover:before {
  color: white;
}

/**/
/**landing page raclette**/
.haut-raclette {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-raclette-haut {
  display: inline;
}

.image-raclette-haut img {
  width: 50%;
  margin: 0;
}

.raclette-bougie {
  display: block;
  width: 50%;
  background-color: #ae6a7a;
  color: white !important;
  font-weight: 900;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.raclette-bougie p {
  padding-left: 50px;
  font-size: 3vw;
  line-height: 1.7;
}

.testt p {
  padding-left: 50px;
  font-size: 3vw;
  line-height: 1.7;
}

.testt {
  padding-left: 50px;
  font-size: 3vw;
  line-height: 1.7;
}

.slick-nav_main,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-15px);
}

.slick-nav_main .next,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .next,
.slick-nav_main .prev,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .prev {
  position: absolute;
  height: 30px;
  width: 30px;
  background: transparent;
  cursor: pointer;
}

.slick-nav_main .next:before,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .next:before,
.slick-nav_main .prev:before,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .prev:before {
  content: "";
  position: absolute;
  background-color: black;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: block;
  z-index: -1;
  transition: opacity 0.2s ease-in-out;
}

.slick-nav_main .next:hover:before,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .next:hover:before,
.slick-nav_main .prev:hover:before,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .prev:hover:before {
  opacity: 0.4;
}

.slick-nav_main .next,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .next {
  right: 10px;
  transform: rotate(180deg);
}

.slick-nav_main .prev,
body.ap5-pack-page .leftblock .dimage_cont .slick-nav .prev {
  left: 10px;
}

body.ap5-pack-page #wrapper {
  padding-top: 40px;
}

body.ap5-pack-page .breadcrumb {
  display: none !important;
}

body.ap5-pack-page #product_pack {
  display: flex;
  flex-direction: column;
}

body.ap5-pack-page h1 {
  color: #333333;
  font-size: 26px;
  text-align: center;
  margin-bottom: 60px;
}

body.ap5-pack-page .adp_mainrow {
  position: relative;
}

#pack_loader {
  --header_height: 155px;
  position: fixed;
  left: 0;
  top: var(--header_height);
  height: calc(100% - var(--header_height));
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

#pack_loader ~ * {
  filter: blur(10px);
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #3297e0;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body.ap5-pack-page #accessories_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

body.ap5-pack-page #accessories_cont .accessory_title {
  text-align: center;
  width: 100%;
  color: #333333;
  margin-bottom: 20px;
}

body.ap5-pack-page #accessories_cont .accessory_desc {
  text-align: center;
  width: 100%;
}

body.ap5-pack-page #accessories_cont .accessory_desc span {
  color: #96c2d9;
  font-style: italic;
  display: block;
  max-width: 150px;
  margin: 5px auto 30px auto;
}

body.ap5-pack-page #accessories_cont #accessories_list {
  width: 100%;
  padding: 0 25px;
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-arrow {
  position: absolute;
  top: calc(50% - 30px);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background: none;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-arrow:hover {
  opacity: 0.75;
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-arrow img {
  width: 100%;
  filter: brightness(0%);
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-arrow.prev {
  left: 0;
  transform: translateY(-50%);
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-arrow.next {
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

body.ap5-pack-page #accessories_cont #accessories_list .slick-track {
  margin: auto;
}

body.ap5-pack-page #accessories_cont .accessory_cont {
  margin-bottom: 20px;
  position: relative;
}
body.ap5-pack-page #accessories_cont .accessory_cont.accessory_grow {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: accessory_grow;
  animation-name: accessory_grow;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
body.ap5-pack-page #accessories_cont .accessory_cont .isaccessory + .del_accessory {
  display: flex;
}
body.ap5-pack-page #accessories_cont .accessory_cont .del_accessory {
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 15px;
  padding: 10px;
  cursor: pointer;
  font-size: 22px;
  color: #b1b1b1;
  transition: all 0.1s ease-in-out;
}
body.ap5-pack-page #accessories_cont .accessory_cont .del_accessory:hover {
  color: #3297e0;
}

#generate_link_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
#generate_link_div > span {
  margin: 7px;
  font-size: 16px;
}
#generate_link_div .btn span {
  font-size: 14px;
}

.sharelink_cont {
  display: flex;
  margin: 7px;
}

#generate_link_input {
  background-color: #fff;
  color: #0e004c;
  border: solid 1px #e3e3e3;
  box-shadow: 0px 0px 0px 0px;
  height: auto;
  padding: 0.6rem 1rem;
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
  max-width: 100px;
}

#message_cookut {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
#message_cookut .message_cookut_title {
  font-size: 16px;
  margin: 7px;
}

#add_message_in_picture {
  background-color: #fff;
  color: #0e004c;
  border: solid 1px #e3e3e3;
  box-shadow: 0px 0px 0px 0px;
  height: auto;
  padding: 0.6rem 1rem;
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
}

#message_fonts {
  background-image: none;
  background-color: #5e5e5e;
  color: white;
  border: solid 1px #e3e3e3;
  box-shadow: 0px 0px 0px 0px;
  height: auto;
  padding: 0.6rem 1rem;
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
}

.messagecont {
  display: flex;
  margin: 7px;
}

@-webkit-keyframes accessory_grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes accessory_grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
body.ap5-pack-page #accessories_cont .attribute {
  display: flex;
  flex-direction: column;
  max-width: 120px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

body.ap5-pack-page #accessories_cont .attribute .aname {
  margin-top: 5px;
  color: #333333;
}

body.ap5-pack-page #accessories_cont .attribute .bottomcont {
  display: flex;
}

body.ap5-pack-page #accessories_cont .attribute .bottomcont .infotoggle {
  display: none;
}

body.ap5-pack-page #accessories_cont .attribute .infocont {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

body.ap5-pack-page #accessories_cont .attribute .imgcont {
  border-radius: 16px;
  border: 1px solid #acacac;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  position: relative;
}

body.ap5-pack-page #accessories_cont .attribute .imgcont .accessory_engraving {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-weight: 600;
  color: #333;
}

body.ap5-pack-page #accessories_cont .attribute .imgcont img {
  height: auto;
  width: 100%;
}

body.ap5-pack-page .leftblockcont {
  padding-right: 100px;
}

body.ap5-pack-page .leftblock {
  position: -webkit-sticky;
  position: sticky;
  top: 155px;
  max-height: 100vh;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
body.ap5-pack-page .leftblock::-webkit-scrollbar {
  display: none;
}

body.ap5-pack-page .leftblock .dimage_cont {
  position: relative;
}

body.ap5-pack-page .leftblock .dimage_cont img {
  width: 100%;
  height: auto;
}

body.ap5-pack-page .leftblock .dimage_cont #superpos {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  /*           &.imgisloading{ opacity: 0; } */
}

body.ap5-pack-page .leftblock .dimage_cont #superpos img {
  width: auto;
  height: 100%;
}

body.ap5-pack-page .leftblock .dimage_cont .slick-dots {
  bottom: 5px;
}

body.ap5-pack-page .leftblock .dimage_cont .slick-dots li {
  width: 30px;
  height: 30px;
}

body.ap5-pack-page .leftblock .dimage_cont .slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}

body.ap5-pack-page .leftblock .dimage_cont .slick-dots button {
  width: 30px;
  height: 30px;
  padding: 5px;
}

body.ap5-pack-page .leftblock .dimage_cont .slick-dots button:before {
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  opacity: 0.2;
  font-size: 15px;
  color: black;
}

body.ap5-pack-page #ap5-buy-container #ap5-buy-block-container {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;
}

body.ap5-pack-page #ap5-buy-container .ap5-buy-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body.ap5-pack-page #ap5-buy-container .ap5-price-container {
  margin: 0 20px;
}

body.ap5-pack-page #ap5-buy-container .ap5-add-to-cart-container {
  margin: 0 20px;
}

body.ap5-pack-page #ap5-buy-container .adp_expedition,
body.ap5-pack-page #ap5-buy-container .product-additional-info {
  width: 100%;
  text-align: center;
}

body.ap5-pack-page #ap5-buy-container .product-prices {
  display: flex;
  align-items: baseline;
  justify-content: center;
  line-height: initial;
}

body.ap5-pack-page #ap5-buy-container .product-prices span {
  font-size: 40px !important;
  font-weight: 400 !important;
}

body.ap5-pack-page #ap5-buy-container .product-prices .current-price {
  font-size: 40px;
  font-weight: 400;
}

body.ap5-pack-page #ap5-buy-container .product-prices .tax-shipping-delivery-label {
  font-size: 20px;
  margin-left: 5px;
}

body.ap5-pack-page #ap5-buy-container .product-prices #product-availability {
  display: none;
}

body.ap5-pack-page #ap5-buy-container .add button {
  background-color: #96c2d9;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
}

body.ap5-pack-page #ap5-buy-container .add button:hover {
  background-color: #3297e0;
  color: white;
}

body.ap5-pack-page #ap5-buy-container .add button .bag-icon {
  display: none;
}

body.ap5-pack-page #ap5-buy-container .qty {
  display: none;
}

body.ap5-pack-page #ap5-buy-container .social-sharing {
  display: none;
}

body.ap5-pack-page #ap5-buy-container .product-additional-info {
  border: none;
  padding-top: 0;
  margin-top: 5px;
}

body.ap5-pack-page #ap5-buy-container .product-additional-info .ps_checkout {
  display: none;
}

body.ap5-pack-page #ap5-buy-container .product-additional-info #loyalty {
  margin: 0;
}

body.ap5-pack-page .adp_expedition {
  display: none;
}

body.ap5-pack-page .subproductcont {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 50px;
}

body.ap5-pack-page .subproductcont .psection_name {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333333;
  border-bottom: 1px solid #96c2d9;
  width: 100%;
  padding-bottom: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

body.ap5-pack-page .subproductcont .psection_name .counter {
  font-size: 60px;
  color: #bfddf2;
  margin-right: 10px;
}

body.ap5-pack-page .subproductcont .attributescont {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

body.ap5-pack-page .subproductcont .attributecont {
  padding: 0 15px;
  margin-bottom: 20px;
}

body.ap5-pack-page .subproductcont .attributecont.selected .imgcont {
  border: 1px solid #96c2d9;
  box-shadow: 0px 0px 16px 0px rgba(0, 118, 203, 0.6) !important;
}

body.ap5-pack-page .subproductcont .attribute {
  display: flex;
  flex-direction: column;
  max-width: 120px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

body.ap5-pack-page .subproductcont .attribute:hover .imgcont {
  border: 1px solid #96c2d9;
  box-shadow: 0px 0px 16px 0px rgba(0, 118, 203, 0.15);
}

body.ap5-pack-page .subproductcont .attribute .aname {
  margin-top: 5px;
  color: #333333;
}

body.ap5-pack-page .subproductcont .attribute .extraprice {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-top: 5px;
}

body.ap5-pack-page .subproductcont .attribute .bottomcont {
  display: flex;
}

body.ap5-pack-page .subproductcont .attribute .bottomcont .infotoggle {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-top: 5px;
}

body.ap5-pack-page .subproductcont .attribute .bottomcont .infotoggle:hover img {
  filter: brightness(0) saturate(100%) invert(13%) sepia(12%) saturate(6326%) hue-rotate(228deg) brightness(105%) contrast(137%);
}

body.ap5-pack-page .subproductcont .attribute .infocont {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.packorigin_return {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333333 !important;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 30px;
}

.packorigin_return svg {
  height: 30px;
  width: 30px;
  margin-top: 2px;
}

.packorigin_return svg line {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1);
}

.packorigin_return:hover {
  color: #3297e0 !important;
}

.packorigin_return:hover svg line {
  stroke: #3297e0;
}

body.ap5-pack-page .subproductcont .attribute .infotoggle {
  cursor: pointer;
}

body.ap5-pack-page .subproductcont .imgcont {
  border-radius: 16px;
  border: 1px solid #acacac;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  transition: all 0.2s ease-in-out;
}

body.ap5-pack-page .subproductcont .imgcont img {
  height: auto;
  width: 100%;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-dialog {
  max-width: 600px;
  padding: 0;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-dialog button.close {
  position: absolute;
  right: 15px;
  top: 15px;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-dialog button.close img {
  height: 24px;
  width: 24px;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-content {
  background-color: white;
  height: auto;
  position: relative;
  border-radius: 16px;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-title {
  text-align: center;
  color: #333333;
  font-size: 18px;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-content {
  padding: 30px;
}

body.ap5-pack-page .subproductcont .attributemodal .modal-header {
  border-bottom: none;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs {
  position: relative;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider {
  list-style: none;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .next,
body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .prev {
  position: absolute;
  height: 40px;
  width: 40px;
  background: transparent;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .next:before,
body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .prev:before {
  content: "";
  position: absolute;
  background-color: black;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: block;
  z-index: -1;
  transition: opacity 0.2s ease-in-out;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .next:hover:before,
body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .prev:hover:before {
  opacity: 0.4;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .next {
  right: 10px;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .next img {
  transform: rotate(180deg);
}

body.ap5-pack-page .subproductcont .attributemodal .mimgs .slick-slider .prev {
  left: 10px;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgcont {
  display: block;
}

body.ap5-pack-page .subproductcont .attributemodal .mimgcont .attr_imgcont img {
  height: auto;
  width: 100%;
  /*  display: block !important; */
}

body.ap5-pack-page .subproductcont .attributemodal .minfocont {
  text-align: center;
}

body.ap5-pack-page .subproductcont .attributemodal .pdesc {
  color: #333333;
}

body.ap5-pack-page .subproductcont .attributemodal .extraprice {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
}

body.ap5-pack-page #blockcart-modal .col-md-7 {
  flex: 0 0 40%;
  max-width: 40%;
}

body.ap5-pack-page #blockcart-modal .col-md-5 {
  flex: 0 0 60%;
  max-width: 60%;
}

body.ap5-pack-page #blockcart-modal .modal-content {
  border-radius: 10px;
}

body.ap5-pack-page #blockcart-modal .modal-header {
  background-color: #96c2d9;
  color: white;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

body.ap5-pack-page #blockcart-modal .btn-primary {
  background-color: #96c2d9;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  font-size: 16px;
  margin-bottom: 20px !important;
}

body.ap5-pack-page #blockcart-modal .btn-primary:hover {
  background-color: #3297e0;
  color: white;
}

body.ap5-pack-page #blockcart-modal .cart-products-count {
  text-align: center;
}

body.ap5-pack-page #blockcart-modal .cart-content {
  text-align: center;
  position: relative;
  max-width: 305px;
  margin: 0 auto;
}

body.ap5-pack-page #blockcart-modal .cart-content:before {
  content: "";
  background-color: #96c2d9;
  width: 2px;
  height: 120px;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

body.ap5-pack-page #blockcart-modal .cart-content-btn {
  padding: 15px;
  max-width: 400px;
  margin: 0 auto;
}

body.ap5-pack-page #blockcart-modal .btn-secondary {
  background-color: #acacac;
  color: white;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  font-size: 16px;
}

body.ap5-pack-page #blockcart-modal .btn-secondary:hover {
  background-color: #6d6d6d;
  color: white;
}

body.ap5-pack-page #blockcart-modal .modal-header .close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
}

body.ap5-pack-page #blockcart-modal .product-name {
  font-weight: 600;
}

body.ap5-pack-page #blockcart-modal .product-name a {
  color: black;
}

body.ap5-pack-page #blockcart-modal .product-attributes > .product-line-info > .label {
  display: none;
}

body.ap5-pack-page #blockcart-modal .attributetitle,
body.ap5-pack-page #blockcart-modal .quantitytitle {
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: 600;
}

body.ap5-pack-page #blockcart-modal .ap5_pack_product_list_cart_summary {
  color: black;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 2px;
}

body.ap5-pack-page #blockcart-modal .ap5_pack_product_list_cart_summary li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 12px;
  line-height: 1.1;
}

body.ap5-pack-page .adp_stagedpay {
  color: #333333;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
}

body.ap5-pack-page .product-add-to-cart {
  padding-top: 0 !important;
}

body.ap5-pack-page #ap5-product-list {
  display: none;
}

#product_pack {
  transition: all 0.2s ease-in-out;
}

#product_pack.isloading {
  opacity: 0.5;
  pointer-events: none;
}

#resp_recaptitle {
  display: none;
}

@media (min-width: 1300px) {
  #desktop-header #desktop-header-container {
    max-width: 1320px;
  }
}
@media (min-width: 992px) {
  #main-page-content #header.stuck-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5000;
  }
}
/* Overrides menu abeilles */
#header .menu-abeille .left-col {
  background-color: rgba(255, 255, 255, 0.95);
  color: #0e004c;
}

#header .menu-abeille .right-col {
  color: #0e004c;
}

#header .left-col .link,
#header .counter-wrap .counter li,
#header .menu-abeille .right-col {
  background: #0e004c;
}

#header .counter-wrap .counter li {
  border: solid 1px #0e004c;
}

.rm-pannel .rm-level .icon-arrow::before {
  line-height: 51px;
}

/* Fin override menu abeilles */
/* Language Selector */
.language-selector .dropdown-item {
  display: flex;
  align-items: center;
}
.language-selector .dropdown-item img {
  margin-right: 5px;
}
.language-selector .dropdown-item.active {
  color: white !important;
}
.language-selector .dropdown-item.active:hover {
  color: #474747 !important;
}

/* NEW FOOTER TEMP */
.containerfooter > .row {
  margin: 0;
}

#footer #footer-container-main {
  padding-bottom: 0;
}

#footer-blocks {
  background-color: #fcf8f5;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.footercont_bot {
  background-color: #0e004c;
}

#footer-blocks > .row {
  max-width: 1300px;
  width: 100%;
  margin: 0;
  justify-content: space-between;
}

#footer-blocks .rte img {
  margin-bottom: 10px;
}

#footer-blocks section {
  margin: 25px 0;
}

#footer-blocks .col-md-3 {
  max-width: unset;
  flex: unset;
  width: auto;
}

#footer-blocks .col-md-3 h4 {
  color: #0e004c;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
}

#footer-blocks .col-md-3 p {
  color: #0e004c;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

#footer-blocks img {
  margin: 0 auto;
  display: block;
  max-width: 64px;
}

.footerbot_container,
.footercont_superbot {
  background-color: #0e004c;
}

.footercont_superbot a {
  color: white !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
}

.footercont_bot > .container {
  padding: 15px;
}

.footercont_botcont {
  max-width: 1300px;
  padding: 0 15px;
}
#footer .block-newsletter .block-title,
#footer .footercont_bot .block-title {
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 15px;
  padding: 0;
}

#footer .ps-emailsubscription-block .newsletter-input-group {
  border-radius: 8px;
  border: none;
}

#footer .ps-emailsubscription-block .newsletter-input-group input {
  color: #0e004c;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
}

#footer .ps-emailsubscription-block .newsletter-input-group {
  max-width: 320px;
}

#footer .ps-emailsubscription-block .newsletter-input-group .btn-subscribe {
  color: #0e004c;
  border-left: 1px solid #0e004c;
  border-radius: 0 8px 8px 0;
  font-size: 16px;
  font-weight: 700;
}

#footer .ps-emailsubscription-block .newsletter-input-group .btn-subscribe:hover {
  color: white;
  background-color: #0e004c;
}

#footer .ps-emailsubscription-block .newsletter-input-group .input-subscription {
  color: #0e004c;
  font-size: 16px;
}
#footer .ps-emailsubscription-block .newsletter-input-group .input-subscription::placeholder {
  opacity: 0.5;
}

.linkblock {
  font-size: 16px;
  max-width: 350px;
}

#footer .linkblock a {
  color: white;
  font-weight: 400;
}

#footer .linkblock li {
  margin-bottom: 15px;
}

.footercont_bottop {
  margin-bottom: 45px;
  justify-content: space-between;
}
.footercont_bottop .block-title {
  max-width: 290px;
}

.cms-page-link img {
  width: 22px;
  margin-right: 8px;
}

.footercont_botcont > .row {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  width: 100%;
}

.footercont_botcont .col-md-4 {
  max-width: 350px;
  margin-top: 22px;
  margin-bottom: 22px;
}

.social_title {
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 15px;
  padding: 0;
}

.social_row img {
  height: 30px;
  width: 30px;
  margin-right: 12px;
}

.contactblock .block-title {
  max-width: 290px;
}

/* END */
.subproduct_engraving_cont {
  position: fixed;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(194, 194, 194, 0.7);
}
.subproduct_engraving_cont.show {
  display: flex;
}
.subproduct_engraving_cont .subproduct_engraving {
  margin: auto;
  max-width: 750px;
  background-color: white;
  box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step {
  display: none;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step.active {
  display: flex;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_step_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step p {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #333333;
  padding: 15px 0;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .btn {
  font-size: 16px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .btn-secondary {
  border: 1px solid #777;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step.step_one {
  flex-wrap: nowrap;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step.step_one img {
  max-height: 160px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step.step_one .btn {
  margin: 0 15px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step.step_two p {
  border-bottom: 1px solid #96c2d9;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_fonts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin: 30px 0;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_fonts .font_selection {
  border-radius: 5px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_fonts .font_selection.active {
  box-shadow: 0px 0px 8px 0px rgba(0, 118, 203, 0.6);
  border-color: #0076cb;
  background: white;
  color: #333;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_finishing {
  display: flex;
  font-size: 24px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_finishing > * {
  width: 50%;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_finishing input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
  color: #333;
  border: solid 1px #acaaa6;
  box-shadow: 0px 0px 0px 0px;
  padding: 0.6rem 1rem;
  text-align: center;
}
.subproduct_engraving_cont .subproduct_engraving .close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_text {
  padding: 15px;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_img {
  padding: 0;
  position: relative;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subproduct_engraving_cont .subproduct_engraving .engraving_img .engraving_preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  color: #333333;
  font-family: "Lato", sans-serif;
}

@media (max-width: 767px) {
  .subproduct_engraving_cont .subproduct_engraving {
    max-width: calc(100% - 30px);
  }
  .subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_step_header {
    padding: 30px;
  }
  .subproduct_engraving_cont .subproduct_engraving .engraving_step.step_one {
    flex-wrap: wrap;
  }
  .subproduct_engraving_cont .subproduct_engraving .engraving_step.step_one .engraving_step_header {
    order: 1;
    padding: 5px 15px 15px;
  }
  .subproduct_engraving_cont .subproduct_engraving .engraving_step .engraving_fonts {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: 30px 0;
  }
  .subproduct_engraving_cont .subproduct_engraving .engraving_img .engraving_preview {
    font-size: 42px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 226dpi), only screen and (min-resolution: 2dppx), screen and (max-height: 779px) {
  body.ap5-pack-page #accessories_cont {
    margin-top: 15px;
  }

  body.ap5-pack-page #accessories_cont .accessory_title {
    margin-bottom: 10px;
  }

  body.ap5-pack-page #accessories_cont .attribute .imgcont {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 992px) {
  body.ap5-pack-page .leftblock {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }

  #fbpsc.socialcont-form {
    background: none;
    border: none;
  }
  #fbpsc .btn-connect {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  #fbpsc .btn-connect .fa {
    position: inherit;
    inset: 0;
    margin: 0;
  }
  #fbpsc .btn-connect .btn-title-connect {
    padding: 0 5px;
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  #footer-blocks {
    justify-content: center;
  }

  #footer-blocks .col-md-3 {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .footercont_botcont {
    display: flex;
    flex-wrap: wrap;
    padding: 50px !important;
    padding-bottom: 0 !important;
  }

  .footercont_bottop {
    order: 1;
  }

  .footercont_botbottom {
    order: 0;
  }

  #footer .block-newsletter .block-title,
#footer .footercont_bot .block-title,
.social_title,
.footercont_superbot a {
    font-size: 16px;
  }

  #footer .linkblock a {
    font-size: 14px;
  }

  #footer .contactblock a {
    font-size: 14px;
  }
  #footer .contactblock img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }

  #footer .block-toggle > .block-content {
    opacity: 1;
    visibility: visible;
    height: initial;
  }

  #footer .ps-emailsubscription-block .newsletter-input-group .input-subscription,
#footer .ps-emailsubscription-block .newsletter-input-group .btn-subscribe {
    font-size: 14px;
  }

  /*   #footer .social-links,
  .linkblock {
    margin-top: 35px;
  } */
}
@media (max-width: 350px) {
  #footer-blocks .col-md-3 {
    width: 100%;
  }
}
@media (max-width: 991px) {
  body.ap5-pack-page {
    /*        #ap5-buy-block-container { position: fixed; bottom: 0; right: 0; background-color: white; padding: 15px; box-shadow: 0px -2px 8px 5px rgba(0, 0, 0, 0.12); } */
  }

  body.ap5-pack-page #resp_recaptitle {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #333333;
    border-bottom: 1px solid #96c2d9;
    width: 100%;
    padding-bottom: 25px;
    padding-top: 25px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  body.ap5-pack-page .adp_mainrow {
    flex-direction: column-reverse;
  }

  body.ap5-pack-page .adp_mainrow #accessories_cont {
    margin-top: 20px;
  }

  body.ap5-pack-page .leftblockcont {
    padding-right: 15px;
  }

  body.ap5-pack-page .leftblock {
    z-index: 1;
    position: initial;
  }

  body.ap5-pack-page .leftblock .product-prices span {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  body.ap5-pack-page .subproductcont .psection_name {
    justify-content: flex-start;
    font-size: 16px;
  }

  body.ap5-pack-page .subproductcont .psection_name .counter {
    font-size: 30px;
  }

  body.ap5-pack-page .subproductcont .attributescont {
    justify-content: center;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog {
    padding: 50px;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog .row.no-gutters.align-items-center {
    flex-direction: column;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog .row.no-gutters.align-items-center .col-info {
    text-align: center;
    margin-top: 10px;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog .row.no-gutters.align-items-center .col-info .product-name {
    padding: 20px 0;
    display: block;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog .row.no-gutters.align-items-center .col-info .product-name a {
    color: #333333;
    font-weight: bold;
    font-size: 18px;
  }

  #pack_mobile_wrapper {
    position: fixed;
    width: 100vw;
    box-sizing: border-box;
    left: 0;
    top: 60px;
    z-index: 1;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.08);
  }

  .footer-container .container {
    margin: 0;
    max-width: 100%;
  }
}
.ap5-pack-page div.estimated_delivery_date {
  box-shadow: none;
  margin-top: 0;
  font-size: 16px;
  color: #0e004c;
}

/***************************page produit********/
.product-available {
  font-size: 125% !important;
}

.hipv-videos video {
  width: 100%;
  height: auto;
}

#product:not(.ap5-pack-page) #content-wrapper {
  max-width: 1640px;
  margin: 0 auto;
  padding-top: 50px;
}
#product:not(.ap5-pack-page) .hipv-videos.hipv-product-additional-info {
  line-height: 0;
}
#product:not(.ap5-pack-page) .col-product-image {
  padding-right: 50px;
}
#product:not(.ap5-pack-page) .col-product-image .product-images-large {
  display: grid;
  grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
  gap: 30px;
}
#product:not(.ap5-pack-page) .col-product-info {
  padding-left: 30px;
}
#product:not(.ap5-pack-page) h1.page-title {
  font-size: 21px;
}
#product:not(.ap5-pack-page) .product-info-row {
  position: relative;
  margin-bottom: 90px;
  padding-bottom: 90px;
}
#product:not(.ap5-pack-page) .product-info-row:after {
  content: "";
  position: absolute;
  bottom: -0.5px;
  display: block;
  width: 100%;
  max-width: 1000px;
  height: 1px;
  border-bottom: solid 1px #e3e3e3;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
}
#product:not(.ap5-pack-page) .product-info-row #product-availability {
  display: none;
}
#product:not(.ap5-pack-page) .product-info-row .product-prices {
  font-size: 30px;
  font-weight: 700;
  color: black;
  line-height: 1;
}
#product:not(.ap5-pack-page) .product-info-row .tax-shipping-delivery-label {
  color: #acaaa6 !important;
  font-size: 20px;
  font-weight: 400;
  margin-top: 6px;
}
#product:not(.ap5-pack-page) .product-info-row .product-description-short {
  font-size: 16px;
  line-height: 1.1;
}
#product:not(.ap5-pack-page) .product-info-row .product-description-short p {
  margin-bottom: 18px;
}
#product:not(.ap5-pack-page) .product-info-row .product-actions .product-variants .form-control-label {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#product:not(.ap5-pack-page) .product-info-row .product-actions .product-add-to-cart {
  margin-top: 15px;
}
#product:not(.ap5-pack-page) .product-info-row .product-quantity .qty .input-group {
  text-align: center;
  font-size: 20px;
}
#product:not(.ap5-pack-page) .product-info-row .add-to-cart {
  background-color: #0e004c;
  font-size: 18px;
  padding: 17px 60px;
}
#product:not(.ap5-pack-page) .product-info-row .add-to-cart:hover, #product:not(.ap5-pack-page) .product-info-row .add-to-cart:focus {
  background-color: #514683;
  color: white;
}
#product:not(.ap5-pack-page) .product-info-row .add-to-cart:disabled {
  background-color: #5e5e5e !important;
  opacity: 1;
  cursor: default;
}
#product:not(.ap5-pack-page) .product-info-row .product-additional-info {
  border: none;
}
#product:not(.ap5-pack-page) .product_header_container {
  border-bottom: none;
  margin-bottom: 15px;
}
#product:not(.ap5-pack-page) .product_header_container .page-title {
  margin-bottom: 20px;
}
#product:not(.ap5-pack-page) .abeilles_mention {
  display: flex;
  flex-wrap: wrap;
  background-color: #fcf8f5;
  padding: 20px 12px;
  margin-bottom: 40px;
}
#product:not(.ap5-pack-page) .abeilles_mention img {
  height: 48px;
  width: 48px;
}
#product:not(.ap5-pack-page) .product-additional-info .estimated_delivery_date {
  background-color: #fcf8f5;
  box-shadow: none;
  font-size: 17px;
  padding: 16px;
  color: #0e004c;
  margin-top: 10px;
}
#product:not(.ap5-pack-page) .abeilles_mentionfirst {
  display: flex;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
}
#product:not(.ap5-pack-page) .abeilles_mentionfirst span {
  padding-right: 90px;
}
#product:not(.ap5-pack-page) .abeilles_mentionsecond {
  font-size: 16px;
  text-decoration: underline;
  text-underline-position: under;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}
#product:not(.ap5-pack-page) .js-mailalert {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_title {
  font-size: 16px;
  color: #0e004c;
  font-weight: 500;
  line-height: 1.1;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_form {
  margin-top: 24px;
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_form input {
  height: 54px;
  font-size: 16px;
  min-width: 200px;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_form .btngroup {
  width: auto;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_form .btn-primary {
  background-color: #0e004c;
  font-size: 16px;
  padding: 15px 30px;
  margin-left: 15px;
}
#product:not(.ap5-pack-page) .js-mailalert .notify_form .btn-primary:hover, #product:not(.ap5-pack-page) .js-mailalert .notify_form .btn-primary:focus {
  background-color: #514683;
  color: white;
}
#product:not(.ap5-pack-page) #product-preloader {
  left: 50%;
  transform: translateX(-50%);
  top: 40vh !important;
}
#product:not(.ap5-pack-page) #product-preloader i {
  font-size: 40px;
}
#product:not(.ap5-pack-page) .product-sections {
  user-select: none;
}
#product:not(.ap5-pack-page) .product-sections .section-title {
  font-size: 18px;
  padding-right: 30px;
  text-transform: uppercase;
}
#product:not(.ap5-pack-page) .product-sections .section-title.collapsetitle {
  cursor: pointer;
}
#product:not(.ap5-pack-page) .product-sections .section-title.collapsetitle:not(.collapsed):before {
  transform: rotate(180deg);
}
#product:not(.ap5-pack-page) .product-sections .section-title.collapsetitle:before {
  position: absolute;
  right: 0;
  top: 0;
  font-family: "FontAwesome";
  content: "\f078";
  display: block;
  font-weight: 900;
  font-size: 18px;
  transition: all 0.15s ease-in-out;
}
#product:not(.ap5-pack-page) .product-sections .section-title:after {
  content: "";
  position: absolute;
  bottom: -0.5px;
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: solid 1px #e3e3e3;
  left: 0;
  right: 0;
}
#product:not(.ap5-pack-page) .product-sections .product-description {
  font-size: 16px;
  line-height: 1.2;
}
#product:not(.ap5-pack-page) .product-sections .product-reference {
  display: none;
}
#product:not(.ap5-pack-page) .pquestion_block {
  display: flex;
}
#product:not(.ap5-pack-page) .pquestion_block .pqimgcont {
  height: 80px;
  width: 80px;
}
#product:not(.ap5-pack-page) .pquestion_block .pqtxtont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0;
  color: #0e004c;
  padding-left: 32px;
  line-height: 25px;
}
#product:not(.ap5-pack-page) .pquestion_block .pqtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
#product:not(.ap5-pack-page) .pquestion_block .pqtxt {
  font-size: 17px;
}
#product:not(.ap5-pack-page) .pquestion_block .pqtxt a {
  font-weight: bold;
}
#product:not(.ap5-pack-page) .blockreassurance_product {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
#product:not(.ap5-pack-page) .blockreassurance_product_item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
#product:not(.ap5-pack-page) .blockreassurance_product_item .item-product {
  height: unset;
  float: none;
  margin: 0;
  padding: 0;
  width: unset;
  margin-right: 12px;
}
#product:not(.ap5-pack-page) .blockreassurance_product_item img {
  height: 40px;
  width: 40px;
}
#product:not(.ap5-pack-page) .blockreassurance_product_item p {
  margin-bottom: 0;
  font-size: 17px;
  color: #0e004c;
}
#product:not(.ap5-pack-page) .bootstrap-touchspin .input-group-btn-vertical i {
  top: 50%;
  transform: translateY(-50%);
}
#product:not(.ap5-pack-page) .product-images-large {
  padding-bottom: 45px;
}
#product:not(.ap5-pack-page) .product-images-large .slick-list .slick-center img,
#product:not(.ap5-pack-page) .product-images-large .slick-list .slick-center video {
  padding: 0;
}
#product:not(.ap5-pack-page) .product-images-large ul.slick-dots {
  margin: 16px 0 0 0;
  bottom: 0;
}
#product:not(.ap5-pack-page) .product-images-large ul.slick-dots li {
  margin: 0 8px;
}
#product:not(.ap5-pack-page) .product-images-large ul.slick-dots li button:before {
  font-size: 20px;
}
#product:not(.ap5-pack-page) .product-images-large ul.slick-dots li.slick-active button::before {
  color: #0e004c;
  opacity: 1;
}
#product:not(.ap5-pack-page) .regular-price {
  font-size: 20px;
}

.product_customblocs_cont {
  max-width: 1364px;
  margin: 0 auto;
  /* font-size: 20px; */
  font-size: 18px;
}
.product_customblocs_cont .title {
  /* font-size: 32px; */
  font-size: 26px;
  font-weight: 600;
  line-height: 1.1;
  text-transform: uppercase;
  display: block;
}
.product_customblocs_cont .title.maintitle {
  /* font-size: 40px; */
  font-size: 34px;
}
.product_customblocs_cont p {
  line-height: 1.1;
}
.product_customblocs_cont .txt {
  display: block;
}
.product_customblocs_cont .imgcont img {
  width: 100%;
  height: auto;
}
.product_customblocs_cont .col-pc {
  padding: 0 30px;
}
.product_customblocs_cont .displayBlocProduct0 {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 90px;
}
.product_customblocs_cont .displayBlocProduct0 .col-pc {
  width: 100%;
}
.product_customblocs_cont .displayBlocProduct0 .txt {
  display: block;
}
.product_customblocs_cont .displayBlocProduct0 p {
  max-width: 812px;
  margin: 0 auto;
}
.product_customblocs_cont .displayBlocProduct0 h3 {
  position: relative;
  /* font-size: 32px; */
  font-size: 26px;
  font-weight: 400;
  padding: 24px 0 32px 0;
  margin-bottom: 30px;
}
.product_customblocs_cont .displayBlocProduct0 h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 6px;
  background-color: #0e004c;
}
.product_customblocs_cont .displayBlocProduct1 {
  margin-bottom: 90px;
}
.product_customblocs_cont .displayBlocProduct1 .title {
  margin-bottom: 48px;
}
.product_customblocs_cont .displayBlocProduct1 .pb_txtcont {
  display: flex;
  align-items: center;
}
.product_customblocs_cont .displayBlocProduct2 {
  background-color: #fcf8f5;
  padding: 80px 60px;
  margin-bottom: 90px;
  position: relative;
}
.product_customblocs_cont .displayBlocProduct2 .pbabeille_img {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 60px;
  height: 60px;
}
.product_customblocs_cont .displayBlocProduct3 {
  margin-bottom: 90px;
}
.product_customblocs_cont .displayBlocProduct3 .title {
  margin-bottom: 48px;
}
.product_customblocs_cont .displayBlocProduct3 ul {
  counter-reset: list_counter;
  list-style: none;
  padding-left: 48px;
}
.product_customblocs_cont .displayBlocProduct3 ul li {
  counter-increment: list_counter;
  position: relative;
  line-height: 1.2;
  padding-left: 15px;
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 18px;
}
.product_customblocs_cont .displayBlocProduct3 ul li:before {
  content: counter(list_counter);
  color: #0e004c;
  /* font-size: 24px; */
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: -48px;
  width: 48px;
  height: 48px;
  top: -12px;
  border: solid 6px #fcf8f5;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_customblocs_cont .displayBlocProduct4 {
  background-color: #e8f2fb;
  padding: 80px 60px 100px 60px;
  margin-bottom: 90px;
  text-align: center;
  width: 100%;
}
.product_customblocs_cont .displayBlocProduct4 .txtcont {
  max-width: 1088px;
  margin: 0 auto;
}
.product_customblocs_cont .displayBlocProduct4 .title {
  font-size: 20px;
  text-transform: none;
  margin-bottom: 30px;
}

/** Responsive page produit **/
@media (max-width: 991px) {
  #product:not(.ap5-pack-page) #content-wrapper {
    padding-top: 15px;
  }
  #product:not(.ap5-pack-page) #inner-wrapper {
    max-width: 100%;
  }
  #product:not(.ap5-pack-page) .col-product-image {
    padding: 0;
  }
  #product:not(.ap5-pack-page) .col-product-image .product-images-large {
    display: flex;
  }
  #product:not(.ap5-pack-page) .col-product-image .product-images-large img,
#product:not(.ap5-pack-page) .col-product-image .product-images-large video {
    padding: 15px;
    transition: all 0.15s ease-in-out;
  }
  #product:not(.ap5-pack-page) .col-product-info {
    padding: 0 30px;
  }
  #product:not(.ap5-pack-page) .estimated_delivery_date .estimated_delivery_date_wrapper {
    text-align: center;
  }

  #product:not(.ap5-pack-page) .product-images-large {
    padding-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .footercont_botcont > .row {
    display: flex;
    margin: 0 -15px;
  }
  .footercont_botcont .fillerblock {
    display: none;
  }
  .footercont_botcont .linkblock {
    order: 3;
  }
  .footercont_botcont .contactblock {
    order: 1;
  }
  .footercont_botcont .s_block {
    order: 2;
  }
  .footercont_botcont .nl_block {
    order: 5;
  }

  .footercont_botcont .col-md-4 {
    margin-top: 0;
    margin-bottom: 45px;
  }

  #footer .linkblock li:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  #footer #footer-blocks {
    padding: 15px 18px;
  }
  #footer #footer-blocks .col-md-3:nth-of-type(1), #footer #footer-blocks .col-md-3:nth-of-type(2) {
    margin-bottom: 30px;
  }
  #footer #footer-blocks .col-md-3 p {
    font-size: 14px;
  }
  #footer #footer-blocks .rte img {
    margin: 0 auto;
    margin-bottom: 5px;
  }
  #footer #footer-blocks section {
    margin: 15px 0;
  }

  #product:not(.ap5-pack-page) h1.page-title {
    font-size: 18px;
  }
  #product:not(.ap5-pack-page) .product_header_container .page-title {
    margin-bottom: 15px;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-prices {
    font-size: 20px;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-prices .tax-shipping-delivery-label {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-prices .regular-price {
    font-size: 16px;
  }
  #product:not(.ap5-pack-page) .abeilles_mention {
    padding: 24px 12px 24px 8px;
  }
  #product:not(.ap5-pack-page) .abeilles_mentionfirst {
    width: 100%;
    font-size: 12px;
  }
  #product:not(.ap5-pack-page) .abeilles_mentionfirst span {
    padding-right: 0;
  }
  #product:not(.ap5-pack-page) .abeilles_mentionsecond {
    width: 100%;
    font-size: 14px;
    padding-left: 48px;
    margin-top: 15px;
  }
  #product:not(.ap5-pack-page) .js-mailalert {
    align-items: center;
  }
  #product:not(.ap5-pack-page) .js-mailalert .notify_title {
    text-align: center;
  }
  #product:not(.ap5-pack-page) .product-variants > .product-variants-item {
    margin-right: 0;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-description-short {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-actions .product-variants {
    display: flex;
    flex-direction: column;
    /*    align-items: center; */
    margin: 0;
    margin-bottom: 10px;
  }
  #product:not(.ap5-pack-page) .product-info-row .product-actions .product-variants .form-control-label {
    /*         text-align: center; */
    font-size: 16px;
  }
  #product:not(.ap5-pack-page) .product-info-row .add-to-cart {
    font-size: 16px;
    padding: 15px 40px;
  }
  #product:not(.ap5-pack-page) .product-quantity > .col-qty {
    max-width: 100px;
  }
  #product:not(.ap5-pack-page) .product-quantity > .col-addbtn {
    flex-basis: 0;
    flex-grow: 1;
  }
  #product:not(.ap5-pack-page) .product-additional-info .estimated_delivery_date {
    margin: 0 -30px;
    padding: 16px 31px;
  }
  #product:not(.ap5-pack-page) .product-additional-info .estimated_delivery_date .estimated_delivery_date_content {
    text-align: center;
    font-size: 18px;
    line-height: 1.1;
  }
  #product:not(.ap5-pack-page) .blockreassurance_product_item p {
    font-size: 16px;
  }
  #product:not(.ap5-pack-page) .product-sections .section-title {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .product-sections .section-title.collapsetitle:before {
    font-size: 16px;
  }
  #product:not(.ap5-pack-page) .product-sections .product-description {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .pquestion_block {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #product:not(.ap5-pack-page) .pquestion_block .pqtitle {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 18px;
  }
  #product:not(.ap5-pack-page) .pquestion_block .pqtxt {
    text-align: center;
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .pquestion_block .pqtxt a {
    font-weight: bold;
  }
  #product:not(.ap5-pack-page) .pquestion_block .pqimgcont {
    width: 50px;
    height: 50px;
  }
  #product:not(.ap5-pack-page) .pquestion_block .pqtxtont {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
  #product:not(.ap5-pack-page) .product-info-row {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont > .col-pc {
    padding: 0;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .title {
    font-size: 14px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .title.maintitle {
    font-size: 20px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct0 {
    margin-bottom: 40px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct0 h3 {
    font-size: 16px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct0 h3:after {
    width: 80px;
    height: 4px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct1 {
    margin-bottom: 40px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct1 .title {
    margin-bottom: 15px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct1 .pb_txtcont {
    margin-top: 40px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct2 {
    padding: 24px 32px;
    margin-bottom: 40px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct2 .txt > p:first-of-type {
    padding-right: 15px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct2 .pbabeille_img {
    right: 10px;
    top: 8px;
    width: 48px;
    height: 48px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 {
    margin-bottom: 15px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 .title {
    margin-bottom: 20px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 .imgcontcont {
    margin-bottom: 32px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 .pb_row {
    flex-direction: column-reverse;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 ul {
    padding-left: 32px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 ul li {
    font-size: 14px;
    margin-bottom: 12px;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct3 ul li:before {
    line-height: 1;
    font-size: 16px;
    left: -32px;
    width: 32px;
    height: 32px;
    top: -8px;
    border: solid 6px #fcf8f5;
  }
  #product:not(.ap5-pack-page) .product_customblocs_cont .displayBlocProduct4 {
    padding: 32px;
    margin-bottom: 40px;
  }

  #product .js-mailalert .notify_form .btn-primary {
    font-size: 16px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #product .js-mailalert .notify_form input {
    font-size: 16px;
  }
}
@media (max-width: 462px) {
  #product .js-mailalert .notify_form .btn-primary {
    margin: 0;
    margin-top: 15px;
    width: 100%;
  }
  #product .js-mailalert .notify_form .btngroup {
    width: 100%;
  }
}
/*************/
@media screen and (max-height: 900px) {
  body.ap5-pack-page .subproductcont .attributemodal .modal-dialog {
    max-width: 500px;
  }
}
@media screen and (max-height: 800px) {
  body.ap5-pack-page .subproductcont .attributemodal .modal-dialog {
    max-width: 500px;
  }
}
@media screen and (max-width: 767px) {
  #footer #footer-blocks li {
    border-bottom: none;
    padding: 2px 7px;
  }

  .raclette-bougie {
    width: 100%;
  }

  .image-raclette-haut img {
    width: 100%;
  }

  .raclette-bougie p {
    font-size: 7vw;
  }

  body.ap5-pack-page #blockcart-modal .col-md-7,
body.ap5-pack-page #blockcart-modal .col-md-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  body.ap5-pack-page #blockcart-modal .ap5_pack_product_list_cart_summary {
    padding: 2px 20px;
  }

  body.ap5-pack-page #blockcart-modal .cart-content:before {
    content: none;
  }

  body.ap5-pack-page #ap5-buy-container .ap5-buy-block {
    flex-direction: column;
    align-items: center;
  }

  body.ap5-pack-page #ap5-buy-container .ap5-price-container {
    margin: 0;
  }

  body.ap5-pack-page #ap5-buy-container .ap5-add-to-cart-container {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .elementor-column.elementor-sm-16 {
    width: 20%;
  }

  .elementor-element.elementor-element-g30324s .elementor-image-box-content .elementor-image-box-title,
.elementor-element.elementor-element-w9ykbai .elementor-image-box-content .elementor-image-box-title,
.elementor-element.elementor-element-nh9occy .elementor-image-box-content .elementor-image-box-title,
.elementor-element.elementor-element-h1ieu2o .elementor-image-box-content .elementor-image-box-title,
.elementor-element.elementor-element-h1ieu2o .elementor-image-box-content .elementor-image-box-title,
.elementor-element.elementor-element-rafa4kn .elementor-image-box-content .elementor-image-box-title {
    font-size: 1.3vh !important;
  }
}
@media (max-width: 575.98px) {
  body.ap5-pack-page .modal-content {
    min-height: unset;
  }

  body.ap5-pack-page .modal-title {
    padding: 0 10px;
    text-align: center;
  }

  body.ap5-pack-page #blockcart-modal .col-info {
    padding-left: 0;
  }

  body.ap5-pack-page #blockcart-modal .ap5_pack_product_list_cart_summary {
    padding: 2px 10px;
  }

  body.ap5-pack-page #blockcart-modal .attributetitle,
body.ap5-pack-page #blockcart-modal .quantitytitle {
    margin-top: 0;
  }

  body.ap5-pack-page #blockcart-modal .modal-dialog .row.no-gutters.align-items-center .col-info .product-name {
    padding: 10px 0;
  }

  body.ap5-pack-page #blockcart-modal .cart-content-btn {
    padding: 10px 0 0 0;
  }

  body.ap5-pack-page #blockcart-modal .btn-primary {
    margin-bottom: 10px !important;
  }
}
#header .header-nav .right-nav {
  align-items: center;
}

#head-follow-link {
  display: flex;
  width: 100px;
  align-items: center;
}

#head-follow-link img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

#head-follow-link span {
  width: calc(100% - 30px);
  text-align: left;
}

#footer-follow-link {
  margin-top: 15px;
  display: inline-block;
}

.shipup-tracker-search .shipup-tracker-search-submit {
  background-color: #0e004c !important;
  color: #fff !important;
}

.shipup-tracker-search .shipup-tracker-search-submit:hover {
  background-color: #0a0131 !important;
  color: #fff !important;
}

#checkout .col-md-8 .form-control,
#checkout .col-md-8 .custom-checkbox input[type=checkbox] + span:not(.color),
#checkout .col-md-8 .custom-radio,
#checkout .col-md-8 .btn-outline-secondary,
#checkout .col-md-8 .btn-outline-secondary.disabled,
#checkout .col-md-8 .btn-outline-secondary:disabled {
  border: solid 1px #2a2a2a;
}

/* New  Landing Page */
.category-id-32 .container,
.category-id-32 .elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1440px;
}

.elementor-editor-active #elementor .elementor-top-column .elementor-widget-wrap .elementor-inner-section {
  margin-top: 0;
}

.elementor-editor-active #elementor .elementor-element.elementor-widget-empty {
  height: auto;
  background: none;
}

.elementor-editor-active #elementor .elementor-element.elementor-widget-empty:after {
  content: none;
}

.btn-blue a {
  color: #fff !important;
}

.title-after .elementor-heading-title:after,
.text-after:after {
  content: "";
  position: absolute;
  border: 6px solid;
  width: 100px;
  bottom: -40px;
  left: 0;
}

.bloc-align-right {
  display: flex;
  justify-content: flex-end;
}

.display-inline {
  display: inline-block;
}

.option-before:before {
  content: url(../img/Pictogramme_options.svg);
  position: absolute;
  width: 35px;
  top: 3px;
}

.elementor-element-gl0tzs8 {
  margin-left: -25px;
  margin-right: -25px;
}

.btn-blue,
.btn-white {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
  left: 50%;
  display: inline-block;
  width: auto;
  /*font-size: 25px;
  min-width: 193px;
  ;*/
  white-space: nowrap;
}

.btn-white-middle {
  display: inline-block;
  width: auto;
  white-space: nowrap;
}

.arrow-notice .slick-arrow {
  width: 50px;
  background: none;
}

.arrow-notice .slick-prev {
  left: -50px;
  transform: translateY(-50%) rotate(180deg);
}

.arrow-notice .slick-next {
  right: -50px;
}

.arrow-notice .slick-arrow:before {
  content: url(../img/Pictogramme_fleche_rose.svg);
  width: 30px;
}

.pictos {
  width: 125px;
  margin: auto;
}

@media (max-width: 991px) {
  .pictos {
    width: 90px;
  }
}
.pictos-line {
  max-width: 865px;
  margin: auto;
}

.sticker {
  max-width: 180px;
  position: absolute;
  top: 0;
  right: -55px;
  z-index: 2;
}

.sticker .elementor-row,
.display-column .elementor-row,
.display-column-choice .elementor-row {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sticker .elementor-row .green-column {
  width: 100%;
  max-width: 180px;
  padding: 0;
  margin: 0;
}

.sticker .elementor-row .img-column {
  width: 220px;
}

.img-absolute {
  position: absolute;
  top: 0;
}

.title-green .elementor-widget-container {
  background: #e7f3e8;
  padding: 5px 5px 0 5px;
  max-width: 300px;
}

.title-green {
  margin-top: auto;
}

.subtitle-green .elementor-widget-container {
  background: #e7f3e8;
  padding: 0px 5px 5px 5px;
  max-width: 300px;
}

.column-column {
  width: 100% !important;
}

.column-column.last {
  margin-top: auto;
}

.display-column-choice,
.display-column-choice .elementor-column-gap-default,
.display-column-choice .elementor-row {
  height: 100%;
}

.display-column-choice .column-column {
  margin-right: auto;
}

.display-column,
.display-column .elementor-column-gap-default,
.display-column .elementor-row,
.display-column .elementor-column {
  height: 100%;
}

.max-column-width {
  max-width: 375px;
}

.display-column .elementor-element-populated {
  align-items: center;
}

.display-column .elementor-column:nth-child(1) {
  margin-bottom: 5px;
}

.display-column .elementor-column:nth-child(2) {
  margin-top: 5px;
}

.align-items-start {
  align-items: flex-start;
}

.title-pink .elementor-heading-title {
  background: #fce4e0;
}

.title-pink .elementor-heading-title span {
  position: relative;
  top: -15px;
}

.carrousel-dot .slick-dots li {
  width: 30px;
  height: 30px;
}

.carrousel-dot .slick-dots li button:before {
  font-size: 15px !important;
}

.column-slider div {
  height: 100% !important;
}

.horizontal .elementor-divider-separator {
  border-left-style: solid;
  border-left-width: 10px;
  border-left-color: #e78c99;
  border-top: none;
  height: 60px;
  width: auto !important;
}

.label-blue {
  position: absolute;
  top: 0;
  z-index: 2;
  background: #201f43;
  padding: 25px 10px 15px;
  left: 8%;
}

.label-blue.right {
  left: 50%;
}

.d-flex-column .elementor-widget-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between;
}

.picto-clean {
  max-width: 50px;
  width: 50px;
}

.display-flex-inline .elementor-widget-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  width: auto;
  padding: 5px 15px 5px 10px;
  border-radius: 10px;
}

@media (max-width: 1440px) {
  .category-id-32 .elementor-top-section {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
.text-size {
  max-width: 610px;
}

.enlarge-section .elementor-row {
  margin-left: -25px;
  margin-right: -25px;
  flex: 1 0 100%;
}

.schema {
  max-width: 300px;
}

.display-middle,
.display-middle > .elementor-column-gap-default {
  height: 100%;
}

.display-middle > .elementor-column-gap-default {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .col-width-60 {
    width: 60% !important;
  }

  .col-width-40 {
    width: 40% !important;
  }
}
#myVideo_desktop {
  display: block;
}

#myVideo_mobile {
  display: none;
}

@media (max-width: 767px) {
  .category-id-32 .carrousel-full {
    padding: 0 !important;
  }

  .btn-blue {
    width: calc(100% + 18px);
  }

  #myVideo_desktop {
    display: none;
  }

  #myVideo_mobile {
    display: block;
  }

  .bloc-video,
.img-wide {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
  }

  .img-wide .slick-slide-image {
    width: 100vw;
  }

  .text-size {
    max-width: 280px;
  }

  .text-after:after {
    width: 250px;
    border: 4px solid;
    bottom: -25px;
  }

  .enlarge-section .elementor-row {
    margin-left: -7px;
    margin-right: -7px;
  }

  .already .elementor-widget-wrap {
    width: 50%;
  }

  .display-desktop {
    display: none;
  }

  .toogle-p .elementor-toggle {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
  }

  .toogle-p .elementor-toggle-title {
    background-color: #d0d7d3;
    margin-top: 5px;
    padding: 10px 55px !important;
  }

  .toogle-p .elementor-toggle-content {
    background-color: #d0d7d3;
    padding: 10px 55px !important;
  }

  .toogle-p .elementor-toggle-icon {
    position: absolute;
    right: 0;
  }

  .toogle-p .elementor-toggle-icon .fa {
    transform: rotate(90deg);
  }

  .toogle-p .elementor-toggle-icon .fa:before {
    content: "\e033" !important;
    font-family: "linecons";
  }

  .toogle-p .elementor-toggle-title.active .elementor-toggle-icon .fa {
    transform: rotate(270deg);
  }

  .arrow-notice.middle .slick-next {
    right: -25px;
  }

  .arrow-notice.middle .slick-prev {
    left: -25px;
  }
}
@media (min-width: 768px) {
  .display-mobile {
    display: none;
  }
}
.video-background::-webkit-media-controls-panel,
.video-background::-webkit-media-controls-start-playback-button {
  display: none !important;
}

/*# sourceMappingURL=assets/css/maps/custom.css.map */